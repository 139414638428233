.bannerSlideContainer {
    position: relative;
    width: 100%;
    height: 100%;

    &.cursor-grabbing .bannerSlide {
        &.swiper {
            cursor: grabbing;
        }
    }
}

.bannerSlide {
    &.swiper {
        width: 100%;
        height: 100%;
    }
      
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
      
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.custom-pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $outer-space;
    padding: 8px 0px;

    > .swiper-pagination-bullet {
        max-width: 120px;
        width: 100%;
        background-color: $white-40-opacity;
        box-shadow: 1px 1px 3px 0px $black-30-opacity;
        border-radius: 8px;
        opacity: 1;
        height: 12px;
    }

    > .swiper-pagination-bullet-active {
        background-color: $golden-yellow;
    } 
}



.custom-buttons {
    display: flex;
    justify-content: center;
    gap: 24px;
    padding: 8px 0px;
    background-color: $outer-space;
}