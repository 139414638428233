.btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $ultramarine-blue;
    padding: 12px 24px;
    color: $white;
    height: 43px;

    &:hover {
        background-color: $light-sky-blue;
    }

    &:disabled {
        background-color: $light-gray-1;
        color: $light-gray-2;
        cursor: not-allowed;
    }
}

.btn-primary-cms, .btn-secondary-cms {
    padding: 12px 24px;

    &:hover {
        background-color: $eerie-black-25-opacity;
    }

    &.selected {
        color: $white;
        background-color: $eerie-black;
    }

    &:disabled {
        background-color: $light-gray;
        border-color: $light-gray;
        color: $silver-chalice;
        cursor: not-allowed;
    }
}

.btn-primary-cms {
    border: 2px solid $eerie-black;

    &.min {
        padding: 8px;
    }
    
    &.black {
        color: $eerie-black;
    }

    &.fw600 {
        font-weight: 600;
    }
}

.btn-secondary-cms {
    border: 2px solid transparent;
}

.btn-secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    padding: 12px 24px;
    border: 2px solid $ultramarine-blue;
    color: $ultramarine-blue;

    &:hover {
        background-color: $light-sky-blue;
        color: $white;
        border-color: $light-sky-blue;
    }
}

body.dark {
    .btn-primary:disabled {
        background-color: $gray;
        color: $silver-sand;
    }
}