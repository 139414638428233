.gallerySlide.swiper {
    width: 100%;
    height: 100%;

    @media (min-width: 1140px) {
        width: 1024px;
    }

    .swiper-slide {
        text-align: center;
        cursor: pointer;
        font-size: 18px;
        background: $white;
        
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s ease;
        transition: width .3s ease-in !important;
        
        @media (min-width: 1140px) {
            width: 175px !important;
        }
    }

    .swiper-slide-active {
        @media (min-width: 1140px) {
            width: 650px !important;
        }
    }

    .swiper-pagination-bullet {
        background-color: $outer-space;
    }

    .swiper-pagination-bullet-active {
        background-color: $light-sky-blue;
    }
}

body.dark {
    .gallerySlide.swiper {
        .swiper-pagination-bullet {
            background-color: $light-gray-2;
        }
    
        .swiper-pagination-bullet-active {
            background-color: $golden-yellow;
        }
    }
}