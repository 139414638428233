
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 90px;
    gap: 24px;
    max-width: 1920px;
    margin: 0 auto;
}

.imgContent {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.medias {
    display: flex;
    align-items: center;
    gap: 40px;
}

.textContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.ibama {
    display: flex;
    align-items: center;
    gap: 12px;

    > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        
        > span {
            text-transform: uppercase;
        }
    }
}

.linkContent {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.links {
    display: flex;
    gap: 16px;
}

.copyright {
    display: flex;
    flex-direction: column;
    text-align: right;
}