
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.embla {
    
    width: 100%;
    cursor: grabbing;
}
  
.embla__container {
    display: flex;
}

.embla__slide {
    flex: 0 0 auto;
}
  