
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.main {
    display: flex;
    gap: 12px;
    align-items: center;

    > a {
        line-height: 75%;
    }
}