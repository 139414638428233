
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 48px;
    max-width: 1920px;
    margin: 0 auto;
    
    button, p, h1, h2, h3, span, input, textarea, label {
        font-family: Montserrat;
    }

    > nav > ul {
        display: flex;

        > li > a {
            padding: 8px 16px;
            color: $granite-gray;

            &.activeLink {
                background-color: $light-gray;
            }
        }
    }

    > button {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}