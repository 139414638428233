
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.header {
    height: 116px;
    transition: height .3s ease-in-out;

    &.transition {
        height: 80px;
        transition: height 0s ease-in-out;
    }

    background-color: $white;
}

.main {
    width: 100%;
    position: absolute;
    transition: top .3s ease-in-out;
    z-index: 3;
    top: 36px;
    background-color: $white;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.10);

    &.headerFixed {
        top: 0;
        position: fixed;
        transition: top 0s ease-in-out;
    }
}

.menu {
    width: 100%;
    display: flex;
    max-width: 1920px;
    margin-left: 50%;
    transform: translateX(-50%);
    justify-content: space-between;
    align-items: center;
    padding: 0px 48px;
    height: 80px;
}

.midContent {
    > button {
        padding: 0 16px;
        height: 35px;

        &:hover, &.active {
            background-color: $light-gray-1;
        }
    }
}

.rightContent {
    display: flex;
    align-items: center;
    gap: 24px;
}

.mode {
    line-height: 75%;
}

.alert {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0px 48px;
    height: 36px;
    background-color: $white;

    > button {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;

        > span {
            color: $amaranth;
        }
    }
}

.nav {
    background-color: $cultured;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.10);
}

.explore {
    display: flex;
    gap: 40px;
    max-height: 0;
    overflow: hidden;
    padding: 0 48px;
    max-width: 1920px;
    margin: 0 auto;
    transition: all .3s ease;

    > li {
        flex: 1;

        > a {
            display: flex;
            flex-direction: column;
            text-align: center;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    
            > span {
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 16px;
                font-weight: 700;
                line-height: normal;
                font-size: 20px;
                color: $raisin-black;
                background-color: $white;
            }

            &:hover {
                > span {
                    background-color: $light-gray-1;
                }
            }

            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $ultramarine-blue;
                height: 124px;
            }
        }
    }

    &.show {
        padding: 48px;
        max-height: 100vh;
    }
}

.products {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-height: 0;
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 48px;
    transition: all .3s ease;
    width: 100%;

    &.show {
        padding: 48px;
        max-height: 100vh;
    }
}

.selectModel {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;

    &:hover {
        background-color: $light-gray-1;
    }

    &.active {
        background-color: $golden-yellow;
        color: $raisin-black;
    }
}

.machines {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    max-width: 152px;
    text-align: center;
}

:global(body.dark) {
    .main, .header, .alert {
        background-color: $jet;
    }

    .nav {
        background-color: $raisin-black;
        box-shadow: 0px 8px 8px 0px rgba(192, 192, 192, 0.1);
    }

    .selectModel, .midContent > button {
        &:hover {
            background-color: $gray;
        }
    }

    .midContent > button.active {
        background-color: $gray;
    }

    .explore > li > a {
        > span {
            color: $cultured;
            background-color: $eerie-black;
        }

        &:hover {
            > span {
                background-color: $gray;
            }
        }
    }
}