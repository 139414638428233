
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.header {
    height: 86px;
}

.main {
    position: fixed;
    background-color: $white;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.10);
    z-index: 3;
    width: 100%;
}

.menu {
    display: flex;
    padding: 24px 16px;
    justify-content: space-between;
    align-items: center;
}

.content {
    display: flex;
    align-items: center;
    gap: 16px;

    > button {
        line-height: 75%;
    }
}

.mode {
    padding: 6px;
}

.contact {
    padding: 16px 32px;
    width: 100%;
}

.contactOpen {
    padding: 16px 32px 88px 32px;
    width: 100%;
}

.menuOpen {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease;
    background: $cultured;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.10);

    &.show {
        max-height: calc(100vh - 86px);
        padding: 24px 0;
        overflow: scroll;
        scrollbar-width: none;
    }

    > button {
        color: $dark-gray;
        padding: 16px 32px;
        display: flex;
        width: 100%;
        gap: 8px;
        align-items: center;
    }

    > button:first-child {
        > span {
            color: $amaranth;
        }
    }

    > button:not(:first-child) {
        justify-content: space-between;
    }
}

.explore {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease;
    width: 100%;

    &.show {
        padding: 8px 16px;
        max-height: 100vh;
    }

    > li {
        height: 80px;

        > a {
            font-size: 20px;
            padding-left: 16px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

            > span {
                width: calc(100% - 120px);
            }

            > div {
                width: 120px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $ultramarine-blue;
            }
        }
    }
}

.products {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    max-height: 0;
    transition: all .3s ease;
    overflow: hidden;

    &.show {
        padding-top: 16px;
        max-height: 1000vh;
        overflow: unset;
    }
}

.productContent {
    overflow-x: hidden;
}

.selectModel {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;

    &.active {
        background-color: $golden-yellow;
        color: $raisin-black;
    }
}

.machines {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    padding-bottom: 16px;
    
    > a {
        display: flex;
        gap: 16px;
        flex-direction: column;
        align-items: center;
        max-width: 152px;
        text-align: center;
    }
}



:global(body.dark) {
    .main {
        background-color: $jet;
    }

    .menuOpen {
        background-color: $eerie-black;
    }
}