@import '../mixins/_typography';

.h2-regular {
    @include h2-style(24px, 600);
    color: $ultramarine-blue;
}

.h2-medium {
    @include h2-style(28px, 700);
    color: $ultramarine-blue;
}

.h2-bold {
    @include h2-style(32px, 800);
    color: $ultramarine-blue;
}

.defesa {
    font-size: 14px;
    line-height: 150%;
}

@media (min-width: 1140px) {
    .h2-regular {
        @include h2-style(36px, 600);
    }
    
    .h2-medium {
        @include h2-style(32px, 700);
    }
    
    .h2-bold {
        @include h2-style(36px, 800);
    }
}

.AZUL-AGRO {
    font-family: "AZUL AGRO";
    color: $raisin-black;
    font-size: 24px;
    font-weight: 400;

    transform: rotate(0deg);
    transition: all .3s ease;

    &.rotate {
        transform: rotate(180deg);
    }
}

body.dark {
    .h2-regular, .h2-medium, .h2-bold, .btn-secondary {
        color: $white;
    }

    .btn-secondary {
        border-color: $white;
    }
}
